<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="lease-content">
      <div class="left">
        <el-tabs v-model="activeName" tab-position="left" style="height: 200px">
          <el-tab-pane v-for="item in dict_agree_list" :key="item.key" :label="item.name" :name="item.key"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <div class="title">{{ title }}</div>
        <Editor ref="editor" :showBtn="true" :content.sync="content"></Editor>
        <div style="margin-top: 20px">
          <el-button v-checkbtn="isShowBtn(AUTH_BTN.agree_list_add)" type="primary" size="small" @click="save">保存</el-button>
          <el-button type="" size="small" @click="cancel">取消</el-button>
        </div>
      </div>
    </div>
    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getDetailAPI, editAPI } from './api'
import { dict_agree_list } from '@/enum/dict.js'
import Editor from '@/components/editor/index.vue'
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { Editor },
  data() {
    return {
      activeName: 'privacy_agreement',
      content: '',
      dict_agree_list
    }
  },
  watch: {
    activeName: {
      immediate: true,
      handler(newV, oldV) {
        this.getDetail()
      }
    }
  },
  mounted() {},
  computed: {
    title() {
      return this.dict_agree_list.find(item => item.key == this.activeName)?.name
    }
  },
  methods: {
    async getDetail() {
      const res = await getDetailAPI(this.activeName)
      this.content = res.values
    },
    save() {
      let data = { key: this.activeName, describe: this.title, values: this.content }

      editAPI(data).then(() => {
        this.$message.success('保存成功')
        this.getDetail()
      })
    },
    cancel() {
      this.getDetail()
      // 初始化编辑器
      this.$refs.editor.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .lease-content {
    display: flex;
    .left {
      width: 150px;
      .el-tabs /deep/ {
        .el-tabs__nav-wrap.is-left::after {
          width: 0;
        }
      }
    }
    .right {
      flex: 1;
      .title {
        margin-bottom: 10px;
        margin-top: 8px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
</style>
