import { http } from '@/http/axios.js'
export function getCategoryListAPI() {
  return http({
    url: '/company/basic/setting/getKeyList',
    method: 'get'
  })
}
// 详情
export function getDetailAPI(key) {
  return http({
    url: '/company/basic/setting/detail',
    method: 'get',
    params: { key }
  })
}
// 编辑
export function editAPI(data) {
  return http({
    url: '/company/basic/setting/add',
    method: 'post',
    data
  })
}
